















































import {Component, Prop, Vue} from "vue-property-decorator";
import {User} from "@/smartmsi";
import MField from "@/components/MField.vue";
import Field from "@/components/Field.vue";
@Component({
  components: {Field, MField}
})
export default class UserForm extends Vue {

  @Prop() userId!: number;

  user: Partial<User> = {};
  oldName = '';

  mounted() {
    // get or create user object
    if (this.userId) {
      this.$api.get(`user/${this.userId}`).then(j => {
        this.user = j.data;
        this.oldName = j.data.name;
      });
    } else {
      this.user = {
        status: 'active',
      }
      this.generatePassword();
    }
  }


  generatePassword() {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const size = 12;

    let password = '';
    for (let i = 0; i < size; i++) {
      password += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    this.$set(this.user, 'password', password);
  }

  onSubmit() {
    const call = this.userId
        ? this.$api.patch(`user/${this.userId}`, this.user)
        : this.$api.post(`user`, this.user)

    call.then(j => {
      this.$emit('save', j.data);
    }).catch(err => {
      if (err.response && this.$refs.form) {
        // @ts-ignore
        this.$refs.form.setErrors(err.response.data);
      }
      this.$snack.validationError(err.response.data)
    });
  }

  get rules() {
    return this.$store.state.rules.user;
  }

}
